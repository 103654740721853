import React from "react";
import Card from "./card";

const Articles = ({ blogs }) => {
  return (
    <section className="container py-16">
      <div className="flex items-center justify-between flex-row flex-wrap">
          {blogs.map((blog) => {
            return (
              <Card 
                blog={blog.node}
                key={`${blog.node.slug}`}
              />
            );
          })}
      </div>
    </section>
  );
};

export default Articles;