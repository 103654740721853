import React from "react";
import { Link } from "gatsby";
import Image from "../components/image"
import Moment from "react-moment";

const Card = ({ blog }) => {
  return (
    <div className="lg:w-[32%] md:w-[49%] sm:w-[49%] w-full px-4 mb-12">
    <div className="bg-[#F3F3F3] rounded-lg shadow-lg" style={{minHeight:'380px'}}>
    <Link to={`/article/${blog.slug}/`} className="uk-link-reset">
      <div className="relative">
        <div className="uk-card-media-top">
           <Image media={blog.image} className="mr-auto w-full h-60 rounded-t" />
        </div>
        <div className="p-4 h-28">
          <p className="uppercase font-semibold text-sm text-gray-500">
            {blog.category}
          </p>
          <p className=" font-bold text-md text-[#151517] leading-5">
            {blog.title}
          </p>
            
        </div>
        <div className="flex items-left flex-row gap-2 px-4  absolute">
              <div>
              <Image media={blog.authorPicture} className=" w-8 h-8 rounded-full object-cover object-center" />
              </div>
              <div>
              {blog.authorNameLink ?
                <a className="text-[#007FA7] uppercase text-sm font-semibold" href={blog.authorNameLink.url} target={blog.authorNameLink.newTab ? "_blank" : "_self"}  rel="noreferrer">
                  {blog.authorNameLink.text}
               </a>
               :
               null
              }
              <p className="text-xs font-normal text-[#676767]">
              <Moment format="MMM DD, YYYY HH:MM">{blog.date}</Moment>
              </p>
              </div>
            </div>
      </div>
    </Link>
    </div>
    </div>
  );
};

export default Card;