import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import ArticlesComponent from "../components/articles";
import Seo from "../components/seo"
import icon from "../images/Shapes.webp"
import { Link } from "react-scroll";


const Blog = () => {
  const data = useStaticQuery(query);
  return (
    <Layout >
       <Seo title="blogs" />
      <div className="uk-section">
      <main
        className="bg-banner  bg-cover md:bg-cover bg-no-repeat lg:h-[60vh] md:h-[50vh] sm:h-[60vh] h-[40vh]"
      >
        <div className="container flex items-center lg:h-[60vh] md:h-[50vh] sm:h-[60vh] h-[40vh]">
          <div className="lg:w-7/12 md:w-7/12 sm:w-7/12 w-full">
            <h2 className=" sm:text-4xl md:text-4xl lg:text-4xl text-2xl mt-12 font-semibold sm:mt-0 lg:mt-0 mb-4 sm:mb-2 banner-text">
            Insightful articles from our experts to help you stay ahead of the curve in the ever-evolving world of technology.
            </h2>
            <div>
            <Link className="flex items-center text-white text-md py-5 cursor-pointer"  to="blog"
     spy={true}
     smooth={true}
     href="#"
     duration={500}>
              Scroll Down
              <img src={icon} className='ml-3 w-8 h-8' alt="saas development company"/>
            </Link>
            </div>
          </div>
        {/*<div className="lg:w-5/12 md:w-5/12 sm:w-5/12 w-full"></div> */}
        </div>
      </main>
        <div id='blog' className="uk-container uk-container-large">
          <ArticlesComponent  blogs={data.allStrapiBlogs.edges} />
        </div>
      </div>
    </Layout>
  );
};

const query = graphql`
query {
  allStrapiBlogs(sort: {order: DESC, fields: date}) {
    edges {
      node {
        strapiId
        slug
        title
        date
        category
        image {
          alternativeText
          mime
          url
          id
        }
        authorNameLink {
          url
          id
          text
          newTab
        }
        authorPicture {
          alternativeText
          mime
          url
          id
        }
        metadata {
          id
          metaDescription
          metaTitle
          twitterCardType
          twitterUsername
          shareImage {
            id
            mime
            url
          }
        }
      }
    }
  }
}
`;

export default Blog;